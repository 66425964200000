import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/js/all'
import $ from 'jquery'
import 'jquery-countdown'
import 'jquery-cookiebar'
import 'summernote'
import 'summernote/dist/summernote-lite.min.css'
import * as ContactHandler from './contact'
import * as CalendarHandler from './calendar'
import * as CarouselHandler from './carousel'
import * as GalleryHandler from './gallery'
import * as DownloadHandler from './download'
import './../../skins/standard/assets/styles/styles.scss'


document.addEventListener('DOMContentLoaded', function () {
    addTopNavigation();

    initMenu();

    $('.summernote').summernote({
        toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'underline']],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['insert', ['link']],
        ],
        height: 350,
        lang: 'pl-PL'
    });
    $.get('api/cookies', function (data) {
        let cookies = JSON.parse(data);
        if (cookies.cookies_showinfo === '1') {
            $.cookieBar({
                fixed: true,
                message: cookies.cookies_message,
                acceptText: 'Akceptuję',
                policyButton: true,
                policyText: 'Dowiedz się więcej',
                policyURL: cookies.cookies_policy,
                bottom: true
            });
        }
    });
    $.get(window.location.href + "?gettemplate", function (template) {
        $.post("/api/layoutconfiguration", {template, ...getArgs()}, function (data) {
            var result = JSON.parse(data);
            for (const [type, configuration] of Object.entries(result)) {
                switch (type) {
                    case '1':
                        ContactHandler.handleContact(configuration);
                        break;
                    case '2':
                        CalendarHandler.handleCalendar(configuration);
                        break;
                    case '3':
                        CarouselHandler.handleCarousel(configuration);
                        break;
                    case '4':
                        GalleryHandler.handleAlbumList(configuration);
                        break;
                    case '5':
                        GalleryHandler.handleAlbum(configuration);
                        break;
                    case '6':
                        DownloadHandler.handleDownload(configuration);
                        break;
                }
            }
        });
    });
});

function getArgs() {
    var input = document.location;
    var regex = /(?:,)(\d+)/gm;

    var matches, output = [];
    while (matches = regex.exec(input)) {
        output.push(matches[1]);
    }

    return output;
}

function initMenu() {
    // Attaching click event listener to the dropdown menu
    $('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
        const $el = $(this);
        const $parent = $el.offsetParent(".dropdown-menu");
        const $subMenu = $el.next(".dropdown-menu");

        $el.toggleClass('active-dropdown');

        if (!$subMenu.hasClass('show')) {
            $parent.find('.show').removeClass("show");
        }

        $subMenu.toggleClass('show');
        $el.parent("li").toggleClass('show');

        return false;
    });

    // Attaching click event listener to the document
    $(document).click(function (e) {
        const $dropdown = $(".dropdown-submenu");

        if (!$dropdown.is(e.target) && $dropdown.has(e.target).length === 0) {
            $dropdown.removeClass('show');
            $dropdown.find('.dropdown-menu').removeClass('show');
            $dropdown.find('a').removeClass('active-dropdown');
        }
    });
}

function addTopNavigation() {
    window.scrollTop = 0;

    jQuery(window).scroll(function () {
        scrollToTop();
    });

    function scrollToTop() {
        let scrollTop = jQuery(document).scrollTop();
        if (scrollTop > 100) {

            if (jQuery("#scrollToTop").is(":hidden")) {
                jQuery("#scrollToTop").show();
            }
        } else {
            if (jQuery("#scrollToTop").is(":visible")) {
                jQuery("#scrollToTop").hide();
            }
        }
    }

    jQuery("a.scrollTo").bind("click", function (e) {
        e.preventDefault();

        var href = jQuery(this).attr('href'),
            _offset = jQuery(this).attr('data-offset') || 0;

        if (href != '#' && href != '#top') {
            jQuery('html,body').animate({scrollTop: jQuery(href).offset().top - getparseInt(_offset)}, 800);
        }

        if (href == '#top') {
            jQuery('html,body').animate({scrollTop: 0}, 800);
        }
    });

    jQuery("#scrollToTop").bind("click", function (e) {
        e.preventDefault();
        jQuery('html,body').animate({scrollTop: 0}, 800);
    });
}

$('[data-countdown]').each(function () {
    let $this = $(this), finalDate = new Date($(this).data('countdown').replace(/\s/, 'T'));
    $this.countdown(finalDate, function (event) {
        $this.html(event.strftime(
            '<div><div>%D</div><i>dni</i></div>'
            + '<div><div>%H</div><i>godzin</i></div>'
            + '<div><div>%M</div><i>minut</i></div>'
            + '<div><div>%S</div><i>sekund</i></div>'))
    });
});

$('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
    if (!$(this).next().hasClass('show')) {
        $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
    }
    var $subMenu = $(this).next('.dropdown-menu');
    $subMenu.toggleClass('show');

    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
        $('.dropdown-submenu .show').removeClass('show');
    });

    return false;
});
